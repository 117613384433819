import React from "react"

import { graphql, useStaticQuery, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import "./hero.css"

const TechStack = () => {
  // const { heroImage } = useStaticQuery(
  //   graphql`
  //     {
  //       heroImage: file(
  //         relativePath: { eq: "new-images/quandary-connect-banner.jpg" }
  //       ) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 1920
  //             layout: CONSTRAINED
  //             formats: [AUTO, WEBP, AVIF]
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const heroImageData = getImage(heroImage)

  // Use like this:
  // const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        // fluid={imageData}
        className="hero-banner tech-stack"
        // preserveStackingContext
        style={{ backgroundPosition: "top center" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-7 mr-auto">
              <div className="tech-stack-section pt-2 pb-5">
                <h3 className="mb-3 text-left-mobile text-cyan font-weight-bold">
                  Take Control of Your Tech Stack
                </h3>
                <p className="font-weight-bold">
                  …and not the other way around.
                </p>
                <p>
                  Our thorough audit reveals how to streamline your software,
                  reduce redundancies, and cut costs, so you can focus on what
                  truly matters. Discover where you can cut, combine, or
                  renegotiate licenses, improve team adoption, and monitor IT
                  expenses in a real-time dashboard – no spreadsheets required.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TechStack
